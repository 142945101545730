import React from 'react';

const QuoteA = ({ item }) => {
    return (
        <tm-quotes-a>
            <tm-quote
                author={item.author}
                img-src={item.img}
                role={item.role}
                text={item.text}
                author-link={item.url}
            ></tm-quote>
        </tm-quotes-a>
    )
}

export default QuoteA;