import React from "react";

const SkillGraph = ({ technical, general }) => {
    let techElements = [];
    let genElements = [];
        general.map((gen, index) => {
            genElements.push(
                <tm-skill-item key={index} title={gen.title} value={gen.value}></tm-skill-item>
            )
        })
        technical.map((tech, index) => {
            techElements.push(
                <tm-skill-item key={index} title={tech.title} value={tech.value}></tm-skill-item>

            )
        })
    return (
        <>
            <tm-skills-grid-a>
                <tm-skills-card-a title="GENERAL SKILLS">
                    {genElements}
                </tm-skills-card-a>
                <tm-skills-card-a title="TECHNICAL SKILLS" theme="warm">
                    {techElements}
                </tm-skills-card-a>
            </tm-skills-grid-a>
        </>
    );

};

export default SkillGraph;