import React, { useState, useEffect } from "react";
import Layout from '../layouts/Layout';
import PropTypes from 'prop-types';
import Cards from '../components/Cards';
import StatA from '../components/StatA';
import SkillGraph from '../components/SkillGraph';
import PromoC from '../components/PromoC';
import QuoteA from '../components/QuoteA';

const RoleTemplate = item => {
	const {
		pageContext: {
			data: {
				title,
				heroImg,
				description,
				pageDescriptionBullets,
				guideLink,
				stats,
				pathSection,
				interviewData,
				technicalSkillsG,
				generalSkillsG
			}
		}
	} = item;
	let statEmpty = 0;
	stats.map(stats => { statEmpty = statEmpty + 1 });

	return (
		<Layout currentItem={item}>
			<div>
				<tm-page class="theme--salesforce">
					<div>
					<tds-breadcrumbs></tds-breadcrumbs>
        			</div>
					<tm-hero-a
						cta-href={guideLink}
						cta-label="Let Trailhead Guide You"
						img-alt="Describe the appearance and function of an image on a page"
						img-src={heroImg}
						subtitle={description}
						title={title}
					></tm-hero-a>

					{statEmpty > 0 && (
						<tm-section-a>
							<StatA item={stats} />
						</tm-section-a>
					)}

					<tm-section-a background="light" title={`Responsibilities of a ${title}`}>
						<Cards items={pageDescriptionBullets} />
					</tm-section-a>

					<tm-section-a title="Top skills requested" description="These are the skills most frequently requested by employers.">
						<SkillGraph technical={technicalSkillsG} general={generalSkillsG} />
					</tm-section-a>

					<tm-section-a background="dark" title={`Start the Path to Become a ${title}`}>
						<PromoC item={pathSection} />
					</tm-section-a>

					<tm-section-a>
						<QuoteA item={interviewData} />
					</tm-section-a>

					<tm-end-cap-a
						background="dark"
						cta-href="https://rc1.trailhead.salesforce.com/cybersecurity/individuals/"
						cta-label="Sign-up"
						img-alt="cybersecurity skills"
						img-src="https://developer.salesforce.com/resources2/wef/endcap.png"
						title="Learn in-demand cybersecurity skills"
					></tm-end-cap-a>

					<tm-section-a description="In partnership with the World Economic Forum." >
						<tm-logo-wall-a>
							<tm-logo img-src="https://developer.salesforce.com/resources2/wef/CGA.png"></tm-logo>
							<tm-logo img-src="https://developer.salesforce.com/resources2/wef/Fortinet.png"></tm-logo>
						</tm-logo-wall-a>
					</tm-section-a>

						<tm-footnote>
							<p>
							*Source: Burning Glass Labor Insights
							</p>
						</tm-footnote>
				</tm-page>
			</div>

		</Layout>
	);
};

export default RoleTemplate;
