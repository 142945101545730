import React from 'react';
import PropTypes from 'prop-types';


class Cards extends React.Component {
    constructor(props) {
        super(props);
        this.items = this.props.items;
        this.iterationValue = 3;
        this.state = {
            itemsToShow: this.iterationValue
        };
    }

    showMore = () => {
        this.setState(prevState => {
            return { itemsToShow: prevState.itemsToShow + this.iterationValue };
        });
    };


    render() {
        const itemsToShow = this.state.itemsToShow;

        return (
            <>
                <tm-card-grid-a id="cards" img-aspect="60%" img-type="left">
                    {
                        this.items.map((item, index) => {
                            if (index < itemsToShow)
                                return (
                                    <tm-grid-item
                                        description={item.text}
                                        img-alt="Describe the appearance and function of an image on a page"
                                        img-src={item.img}
                                        key={`cards-${index}`}
                                    ></tm-grid-item>
                                );

                        })
                    }
                </tm-card-grid-a>
                {
                    itemsToShow < this.items.length && (
                        <div id="wef-show-more" className={`slds-align_absolute-center slds-p-top_xx-large`}>
                            <button className="slds-button slds-button_neutral tds-button_neutral" type="button" onClick={this.showMore} >Show More</button>
                        </div>
                    )
                }

            </>
        ); // return

    } // render
}


Cards.propTypes = {
    items: PropTypes.array.isRequired
};

export default Cards;
