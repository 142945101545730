import React from 'react';

const PromoC = ({ item }) => {
    return (
            <tm-promo-c
                description={item.description}
                img-alt="Describe the appearance and function of an image on a page"
                img-src={item.img}
                primary-cta-href={item.url}
                primary-cta-title={item.urltext}
                title={item.title}
            ></tm-promo-c>
    )
}

export default PromoC;