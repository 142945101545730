import React from 'react';

const StatA = ({ item }) => {
    return (
        <tm-stats-a>
            {
                item.map(item => {
                    return (
                        <tm-stat-item key={item.text} description={item.text} title={item.value}></tm-stat-item>
                    )
                })
            }
        </tm-stats-a>
    )
}

export default StatA;